.contacts {
    /*font-size: calc(10px + 2vmin);*/
}

.contact-container {
    margin: 0 auto;
}

.contact-avatar-container {
    margin: 0 auto;
    position: relative;
    height: 160px;
    width: 160px;

    min-height: 150px;
    min-width: 150px;
    border: 1px solid #2e4379;
    box-shadow: 5px 2px 2px #ACD7CB;
}

.contact-avatar {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.contact-decoration {
    content: '';
    width: 30%;
    height: 30%;
    z-index: 2;
    position: absolute;
    bottom: -15px;
    left: -20px;
}

.contact-info {
    text-align: left;
}

.contact-name {
    display: block;
    font-weight: bold;
    font-size: 1.5rem;

    margin: 10px 0 5px 0;
}

.contact-description {
    font-weight: lighter;
}

.contact-email, .contact-phone {
    margin: 0px 0 5px 0;
    cursor: pointer;
}

@media (max-width: 1070px) or (max-height: 500px) {
    /* smartphones and tablets */
    .contact-info {
        text-align: center;
    }
}