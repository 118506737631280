.header {
    background-color: #ffffff;
    min-height: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    cursor: pointer;
}

.header > .logo {
    height: 20vmin;
    pointer-events: none;
}