.body {
    height: 75vh;
    width: 99vw;
    overflow-y: auto;
    padding: 0 1px 0 1px;
}

hr {
    border: none;
    border-radius: 10px;
    height: 2px;
    width: 50%;
    background-color: #ACD7CB;
    color: #ACD7CB;
}

@media (max-height: 500px) and (orientation: landscape) {
    /* smartphones, Android phones, landscape iPhone */
    .body {
        height: 65vh;
    }

    hr {
        width: 95%;
    }
}

@media (max-width: 500px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
    .body {
        height: 65vh;
    }

    hr {
        width: 95%;
    }
}