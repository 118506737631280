.footer {
    height: 30px;
    background-color: white;
    border-top: 1px solid #ACD7CB;
    width: 100%;
    position: fixed;
    bottom: 0;

    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;

    color: #2e4379;
    font-size: 1rem;
}

.footer > a {
    cursor: pointer;
}